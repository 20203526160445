import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AppDanhMuc, GetValue } from '../enums/app_danhmuc';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            roleName: ['guest'],
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Hệ thống',
        children: [
          {
            type: 'link',
            label: 'Cấu hình hệ thống',
            route: '/he-thong/cau-hinh',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-CauHinh'],
          },
          {
            type: 'link',
            label: 'Người dùng',
            route: '/he-thong/nguoi-dung',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-NguoiDung'],
          },
          {
            type: 'link',
            label: 'Nhóm người dùng',
            route: '/he-thong/nhom-nguoi-dung',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-NhomNguoiDung'],
          },
          {
            type: 'link',
            label: 'Nhóm quyền',
            route: '/he-thong/nhom-quyen',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-NhomQuyen'],
          },
          {
            type: 'link',
            label: 'Chức năng',
            route: '/he-thong/chuc-nang',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-ChucNang'],
          },
          {
            type: 'link',
            label: 'Phân hệ',
            route: '/he-thong/phan-he',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-PhanHe'],
          },
          {
            type: 'link',
            label: 'Site Client',
            route: '/he-thong/site-client',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-SiteClient'],
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Khách hàng',
        children: [
          {
            type: 'link',
            label: 'Tổ chức',
            route: '/khach-hang/to-chuc',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-ToChuc'],
          },
          {
            type: 'link',
            label: 'Site',
            route: '/khach-hang/site',
            icon: 'mat:assignment',
            roleName: ['ISME-HT-Site'],
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Danh mục',
        children: [
          {
            type: 'link',
            label: 'Loại tổ chức',
            route: '/danh-muc/danh-muc-chung/'+GetValue(AppDanhMuc.LOAI_TOCHUC),
            icon: 'mat:assignment',
            roleName: ['ISME-HT-LoaiToChuc'],
          },
          {
            type: 'link',
            label: 'Loại giấy tờ',
            route: '/danh-muc/danh-muc-chung/'+GetValue(AppDanhMuc.LOAI_GIAYTO),
            icon: 'mat:assignment',
            roleName: ['ISME-HT-LoaiGiayTo'],
          },
          {
            type: 'link',
            label: 'Loại nền tảng',
            route: '/danh-muc/danh-muc-chung/'+GetValue(AppDanhMuc.NEN_TANG),
            icon: 'mat:assignment',
            roleName: ['ISME-HT-NenTang'],
          },
          {
            type: 'link',
            label: 'Ngôn ngữ',
            route: '/danh-muc/danh-muc-chung/'+GetValue(AppDanhMuc.NGON_NGU),
            icon: 'mat:assignment',
            roleName: ['ISME-HT-NgonNgu'],
          },
          {
            type: 'link',
            label: 'Loại OTP',
            route: '/danh-muc/danh-muc-chung/'+GetValue(AppDanhMuc.LOAI_OTP),
            icon: 'mat:assignment',
            roleName: ['ISME-HT-LoaiOtp'],
          }
        ]
      },
    ]);
  }
}
