import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';


@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private router:Router, private authService: AuthService<any>){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // if(error.status === 404) {
        //     this.router.navigate(['not-found']);
        // }else if(error.status === 500) {
        //     this.router.navigate(['server-error']);
        // }
        if (error.status === 401) {
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              return this.authService.getValidToken().pipe(
                switchMap(newToken => {
                  request = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${newToken}`
                    }
                  });
                  return next.handle(request);
                })
              );
            }),
            catchError((refreshError) => {
              this.authService.logout();
              return throwError(() => refreshError);
            })
          );
        }
        else{
            console.log(error);
        }
        // Xử lý lỗi
        console.error('Lỗi khi gửi request:', error);
        return throwError(error);
      })
    );
  }
}
