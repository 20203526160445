import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxLoadingModule } from 'ngx-loading';
import { TranslateCacheService } from 'ngx-translate-cache';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterOutlet,
    NgxLoadingModule
  ]
})
export class AppComponent {

  constructor(translateService: TranslateService,
    translateCacheService: TranslateCacheService) {
    translateService.setDefaultLang('vi');
    translateCacheService.init();
  }
}
