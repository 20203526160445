import { Component, OnInit } from '@angular/core';
import { VexPopoverRef } from '@vex/components/vex-popover/vex-popover-ref';
import { MatRippleModule } from '@angular/material/core';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { removeStore } from 'src/app/core/commons/storage';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { LocalStorageService } from 'src/app/core/services/localstore.service';
import { LocalStoreKey } from 'src/app/core/enums/localstore_key';

@Component({
  selector: 'vex-sidenav-user-menu',
  templateUrl: './sidenav-user-menu.component.html',
  styleUrls: ['./sidenav-user-menu.component.scss'],
  imports: [SharedModule, MatRippleModule, RouterLink, MatIconModule],
  standalone: true
})
export class SidenavUserMenuComponent implements OnInit {
  constructor(private readonly popoverRef: VexPopoverRef, private router: Router,
    private translate: TranslateService,
    private localStoreService: LocalStorageService
  ) { }

  ngOnInit(): void { 
  }

  close(): void {

  }

  profile() {
    this.router.navigate(['/user-and-group/user/profile']);
  }

  signOut() {
    /** Wait for animation to complete and then close */
    // firebase logout
    this.localStoreService.logout();
    setTimeout(() => this.popoverRef.close(), 250);
    removeStore();
    this.router.navigate(['/auth/login']);
  }
}
