export enum ChucNang {
  LOGIN_FB = 'login-with-facebook',
  LOGIN_GG = 'login-with-google',
  LOGIN_AP = 'login-with-apple',
  LOGIN = 'token',
  REFRESH_TOKEN = 'refresh-token',
  IMAGE = 'files',
  IMAGE_THUMBNAIL = 'files-thumbnail',
  //------
  BAIDANG = 'bai-dang',
  BAIDANG_NGONNGU = 'bai-dang-ngon-ngu',
  BINH_LUAN = 'binh-luan',
  CHI_NHANH = 'chi-nhanh',
  CHINHANH_LIENHE = 'chi-nhanh-lien-he',
  LOAI_TRANG = 'loai-trang',
  THE_BAIDANG = 'the-bai-dang',
  THE = 'the',
  THELOAI_BAIDANG = 'the-loai-bai-dang',
  THE_LOAI = 'the-loai',
  THELOAI_NGONNGU = 'the-loai-ngon-ngu',
  THICH = 'thich',
  TRANG = 'trang',
  TRANG_NGONNGU = 'trang-ngon-ngu',
  BANG_DIEM = 'bang-diem',
  PHONG_VAN = 'phong-van',
  BANPHONGVAN_TUYENDUNG = 'ban-phong-van-tuyen-dung',
  CHUNG_CHI = 'chung-chi',
  CHUYEN_NGANH = 'chuyen-nganh',
  DANHGIA_UNGVIEN = 'danh-gia-ung-vien',
  KETQUA_TOTNGHIEP = 'ket-qua-tot-nghiep',
  KETQUA_TUYENDUNG = 'ket-qua-tuyen-dung',
  LOAIHINH_DAOTAO = 'loai-hinh-dao-tao',
  QUATRINH_CONGTAC = 'qua-trinh-cong-tac',
  THANHVIEN_BANPHONGVAN = 'thanh-vien-ban-phong-van',
  TRANGTHAI_TUYENDUNG = 'trang-thai-tuyen-dung',
  TRINH_DO = 'trinh-do',
  TUYEN_DUNG = 'tuyen-dung',
  UNG_VIEN = 'ung-vien',
  UNGVIEN_NGUOIDUNG = 'ung-vien-nguoi-dung',
  UNGVIEN_TUYENDUNG = 'ung-vien-tuyen-dung',
  VITRI_TUYENDUNG = 'vi-tri-tuyen-dung',
  CAU_HINH = 'cau-hinh',
  CHUC_NANG = 'chuc-nang',
  DIA_CHI = 'dia-chi',
  FILE_UPLOAD = 'file-upload',
  LIEN_HE = 'lien-he',
  LOAI_GIAYTO = 'loai-giay-to',
  LOAI_OTP = 'loai-otp',
  LOAI_THONGBAO = 'loai-thong-bao',
  LOAI_TOCHUC = 'loai-to-chuc',
  MA_LOI = 'ma-loai',
  MAU_EMAIL = 'mau-email',
  MAU_SMS = 'mau-sms',
  NEN_TANG = 'nen-tang',
  NGON_NGU = 'ngon-ngu',
  NGUOI_DUNG = 'nguoi-dung',
  NGUOIDUNG_LIENHE = 'nguoi-dung-lien-he',
  NHOM_NGUOIDUNG = 'nhom-nguoi-dung',
  NHOM_QUYEN = 'nhom-quyen',
  PHAN_HE = 'phan-he',
  PHAN_QUYEN = 'phan-quyen',
  SITE_CLIENT = 'site-client',
  SITE = 'site',
  THONG_BAO = 'thong-bao',
  TO_CHUC = 'to-chuc',
  DANH_MUC= 'danh-muc'
}
export function GetPath(item: ChucNang): string {
  return item;
}
