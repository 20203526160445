import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export function MAT_PAGINATOR_INTL_PROVIDER_FACTORY(translate: TranslateService) {
  const paginatorIntl = new MatPaginatorIntl();

  const setLabels = () => {
    paginatorIntl.itemsPerPageLabel = translate.instant('items_per_page');
    paginatorIntl.nextPageLabel = translate.instant('next_page');
    paginatorIntl.previousPageLabel = translate.instant('previous_page');
    paginatorIntl.firstPageLabel = translate.instant('first_page');
    paginatorIntl.lastPageLabel = translate.instant('last_page');

    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return translate.instant('item_to_item', {"value1": 0, "value2": length});
        // return `0 của ${length}`;
      }
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return translate.instant('item_to_item_total', {"value1": startIndex + 1, "value2": endIndex, "value3": length});
      // return `${startIndex + 1} - ${endIndex} của ${length}`;
    };
    paginatorIntl.changes.next(); // Cập nhật khi nhãn thay đổi
  };

  translate.onLangChange.subscribe(() => setLabels());
  setLabels();

  return paginatorIntl;
}
