import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'vex-internal-server-error',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './internal-server-error.component.html',
  styleUrl: './internal-server-error.component.scss'
})
export class InternalServerErrorComponent {

}
