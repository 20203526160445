import { authGuard } from './core/guards/auth.guard';
import { noAuthGuard } from './core/guards/no-auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { NotFoundComponent } from './modules/shared/components/not-found/not-found.component';
import { InternalServerErrorComponent } from './modules/shared/components/internal-server-error/internal-server-error.component';
import { ConfirmForgotPasswordComponent } from './modules/auth/components/confirm-forgot-password/confirm-forgot-password.component';

export const appRoutes: VexRoutes = [
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'server-error',
    component: InternalServerErrorComponent
  },
  {
    path: 'xac-nhan-quen-mat-khau',
    component: ConfirmForgotPasswordComponent
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (m) => m.AuthModule
      )
  },
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadComponent: () =>
          import(
            './modules/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: 'danh-muc',
        loadChildren: () =>
          import(
            './modules/danhmuc/danhmuc.module'
          ).then((m) => m.DanhMucModule)
      },
      {
        path: 'he-thong',
        loadChildren: () =>
          import(
            './modules/hethong/hethong.module'
          ).then((m) => m.HethongModule)
      },
      {
        path: 'khach-hang',
        loadChildren: () =>
          import(
            './modules/khachhang/khachhang.module'
          ).then((m) => m.KhachHangModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
];
