<div class="bg-pattern w-full h-full flex flex-col items-center justify-center">
    <div [@fadeInUp] class="card overflow-hidden w-full max-w-md">
      <div class="p-6 pb-0 flex flex-col items-center justify-center">
        <div class="fill-current text-center">
          <img class="w-16" src="assets/img/logo/logo.svg" />
        </div>
      </div>
  
      <div class="text-center mt-4">
        <h2 class="title m-0">{{ 'confirm_password_title' | translate }}</h2>
        <h4 class="body-2 text-secondary m-0">
          {{ 'confirm_password_description' | translate }}
        </h4>
      </div>
  
      <div [formGroup]="formConfirm" class="p-6 flex flex-col">
        <mat-form-field>
          <mat-label>{{ 'confirmation_code' | translate }}</mat-label>
          <mat-icon class="mr-2" matIconPrefix svgIcon="mat:vpn_key"></mat-icon>
          <input formControlName="confirmationCode" matInput required />
          <mat-error *ngIf="formConfirm.get('confirmationCode')?.hasError('required')">
            {{ 'notice_required_infor' | translate }}
          </mat-error>
        </mat-form-field>
  
        <button
          (click)="confirmResetPassword()"
          class="mt-2"
          color="primary"
          mat-raised-button
          [disabled]="!formConfirm.valid"
          type="button">
          {{ 'confirm_verify_code_button' | translate }}
        </button>
      </div>
    </div>
  </div>
