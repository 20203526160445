import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [
    MatFormFieldModule,
  ],
  templateUrl: './app-form-error.component.html',
  styleUrl: './app-form-error.component.scss'
})
export class AppFormErrorComponent {
  @Input() control!: any;
  @Input() errorMessages: { type: string; message: string; }[] | undefined
}
