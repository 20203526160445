import { AuthService } from './modules/auth/services/auth.service';

export function appInitializer(authService: AuthService<any>) {
  return () => new Promise<void>((resolve) => {
    authService.checkAndAutoLogin().subscribe((success) => {
      if (success) {
        authService.navigateToCurrentUrl();
      }
      resolve();
    });
  });
}