import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { getEmail } from 'src/app/core/commons/storage';
import { LocalStoreKey } from 'src/app/core/enums/localstore_key';

@Component({
  selector: 'confirm-forgot-password',
  standalone: true,
  imports: [
    SharedModule
  ],
  animations: [fadeInUp400ms],
  templateUrl: './confirm-forgot-password.component.html',
  styleUrl: './confirm-forgot-password.component.scss'
})
export class ConfirmForgotPasswordComponent {
  private _snackBar = inject(MatSnackBar);
  formConfirm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService<any>,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.initForm();
    this.route.queryParams.subscribe(params => {
      const confirmationCode = params['ma-xac-nhan'];
      if (confirmationCode) {
        this.formConfirm.get('confirmationCode')?.setValue(confirmationCode);
      }
    });
  }

  initForm() {
    this.formConfirm = this.fb.group({
      confirmationCode: ['', Validators.required]
    });
  }

  confirmResetPassword() {
    if (this.formConfirm.valid) {
      const data = {
        "email": localStorage.getItem(LocalStoreKey.accountEmail),
        "maXacNhan": this.formConfirm.get('confirmationCode')?.value
      }
      this.authService.confirmResetPassword(data).subscribe({
        next: (res: any) => {
          if (res.isSuccess) {
            console.log(res);
            const message = this.translate.instant('notice_confirm_password_success');
            this._snackBar.open(message, this.translate.instant('confirm'), {
              duration: 3000,
              panelClass: ['snackbar-success'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
            this.router.navigate(['/auth/login']);
          } else {
            const errorMessage = res.message;
            this._snackBar.open(errorMessage, this.translate.instant('close'), {
              duration: 3000,
              panelClass: ['snackbar-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
            });
          }
        },
        error: (error: any) => {
          console.log(error);
          const errorMessage = error.error.message;
          this._snackBar.open(errorMessage, this.translate.instant('close'), {
            duration: 3000,
            panelClass: ['snackbar-error'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
          });
        }
      })
    }
  }
}
