import { Component, Input, ViewChild } from '@angular/core';
import { NgxLoadingComponent, NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    NgxLoadingModule
  ],
  templateUrl: './app-loading.component.html',
  styleUrl: './app-loading.component.scss'
})
export class AppLoadingComponent {
  @Input() isLoading = false;
  @Input() loadingType:string = ngxLoadingAnimationTypes.cubeGrid;
  @Input() primaryColor: string = '#4f46e5'
  @Input() secondaryColor: string = '#ccc';
  @ViewChild('ngxLoading', { static: false })
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
    public coloursEnabled = false;
    public config = {
      animationType: this.loadingType,
      primaryColour: this.primaryColor,
      secondaryColour: this.secondaryColor,
      tertiaryColour: this.primaryColor,
      backdropBorderRadius: '3px',
    };
  ngxLoadingComponent!: NgxLoadingComponent;
}
