export enum AppDanhMuc {
  LOAI_TOCHUC = "2D976FBA-5BCD-4588-BCD0-78314734DC8C",
  LOAI_GIAYTO = "2FC7B66B-9124-4BCC-9D62-4BAA8A0CB84B",
  NEN_TANG = "058C83C5-C372-470A-A462-F0A3748C630B",
  NGON_NGU = "09E0D293-8A1E-4F5A-85E7-11BF600195A5",
  LOAI_OTP = "B8ABC0FD-9DD0-4632-BEDD-F1C252983B5D"
}
export function GetValue(item: AppDanhMuc): string {
  return item;
}
export function GetEnumKeyFromValue(value: string | null): string | undefined {
  for (const key in AppDanhMuc) {
    if (AppDanhMuc[key as keyof typeof AppDanhMuc] === value) {
      return key; // Trả về tên (key) của enum
    }
  }
  return undefined; // Không tìm thấy
}
