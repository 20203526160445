import { LocalStoreKey } from "../enums/localstore_key";
import {jwtDecode}  from 'jwt-decode';

export function getAccessToken(): string | null {
  return localStorage.getItem(LocalStoreKey.appToken);
}

export function setStorageString(key: string,value: string){
  localStorage.setItem(key,value);
}

export function getDataToken(){
  try{
    let token = getAccessToken()??'';
    if(token){
      const decoder =  jwtDecode(token);
      return decoder;
    }else{
      return null;
    }
  }catch(e){
    return null;
  }
}

export function getIdNguoiDung(){
  return localStorage.getItem(LocalStoreKey.idNguoiDung);
}

export function getIdSite(){
  return localStorage.getItem(LocalStoreKey.idSite);
}

export function getIsRemember(){
  return localStorage.getItem(LocalStoreKey.rememberMe);
}

export function getEmail(){
  return localStorage.getItem(LocalStoreKey.accountEmail);
}

export function getPassword(){
  return localStorage.getItem(LocalStoreKey.accountPassword);
}

export function removeStore(){
  localStorage.removeItem(LocalStoreKey.appToken);
  localStorage.removeItem(LocalStoreKey.refreshToken);
  localStorage.removeItem(LocalStoreKey.idSite);
  localStorage.removeItem(LocalStoreKey.idNguoiDung);
  localStorage.removeItem(LocalStoreKey.app_role);
  localStorage.removeItem(LocalStoreKey.expiredIn);
}

export function removeRememberMe(){
  localStorage.removeItem(LocalStoreKey.accountEmail);
  localStorage.removeItem(LocalStoreKey.accountPassword);
  localStorage.removeItem(LocalStoreKey.rememberMe);
}