import { Injectable, Pipe } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable, firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getAccessToken } from '../commons/storage';
import { BaseResponse } from '../models/base_response.model';
import { url } from 'inspector';
@Injectable({
  providedIn: 'root'
})
export class Api<T>{
  responseType = {
    blob: 'blob',
    arraybuffer: 'arraybuffer',
    json: 'json',
    text: 'text',
    none: ''
  };

  constructor(
    private http: HttpClient,
    private route: Router,
  ) { }


  get language() {
    return localStorage.getItem('lang') ?? '';
  }

  /**
   * Lấy thông tin token từ cookie
   * @returns Token: string
   */
  getToken() {
    return getAccessToken()?.replace(/\"/g, '');
  }

  /**
   * Get method
   * @param path
   */
  get(path: string, queryParams: any = null) {
    var header: any = {
      'Content-Type': 'application/json'
    };
    if (queryParams) {
      let qrParams = new HttpParams({fromObject: queryParams});
      return this.http.get<BaseResponse<T>>(`${environment.domain}/${path}`, this.optionsRequest(header, qrParams, false),).pipe(catchError(this.handleError));
    }
    else
      return this.http.get<BaseResponse<T>>(`${environment.domain}/${path}`, this.optionsRequest(header, {}, false),).pipe(catchError(this.handleError));
  }

  getPage(path: string, page: number, pageSize: number,searchStr: string='',searchKey: string='', sortKey: string='', sortDirection: string='') {
    const header: any = {
      'Content-Type': 'application/json'
    };
    var params: any = {};
    params.pageNumber = page;
    params.pageSize = pageSize;
    if(searchStr != '') {
      if(searchKey!=''){
        params[`Search.${searchKey}`] = searchStr;
      }else{
        params[`Search.Ten`] = searchStr;
      }
    }

    if (sortKey != '') {
      if (sortDirection != '') {
        params[`Sort.${sortKey}`] = sortDirection.toLocaleUpperCase();
      }
      else {
        params[`Sort.${sortKey}`] ="DESC";
      }
    }
    let qrParams = new HttpParams({fromObject: params});
    return this.http.get<BaseResponse<T[]>>(`${environment.domain}/${path}`, this.optionsRequest(header, qrParams, false)).pipe(catchError(this.handleError));
  }

  /**
   * Post method
   * @param path
   * @param data
   */
  post(path: string, data: any) {
    const header = {
      'Content-Type': 'application/json'
    };
    data = JSON.stringify(data);
    return this.http.post<BaseResponse<T>>(`${environment.domain}/${path}`, data, this.optionsRequest(header, {}, false)).pipe(catchError(this.handleError));
  }

  /**
   * Post multipart
   * @param path
   * @param data
   */
  postMultiPart(path: string, data: FormData) {
    return this.http.post<BaseResponse<T>>(`${environment.domain}/${path}`, data, this.optionsRequest()).pipe(catchError(this.handleError));
  }

  /**
   * Put method
   * @param path
   * @param data
   */
  put(path: string, data: any) {
    const header = {
      'Content-Type': 'application/json'
    };
    data = JSON.stringify(data);
    return this.http.put<BaseResponse<T>>(`${environment.domain}/${path}`, data, this.optionsRequest(header, {}, false)).pipe(catchError(this.handleError));
  }

  /**
   * Delete method
   * @param path
   */
  delete(path: string) {
    const header = {
      'Content-Type': 'application/json'
    };
    return this.http.delete<BaseResponse<T>>(`${environment.domain}/${path}`, this.optionsRequest(header, {}, false)).pipe(catchError(this.handleError));
  }

  /**
   * POST download
   * @param path
   * @param data
   */
  download(path: string) {
    const header = {
      'Content-Type': 'application/json'
    };
    return this.http
      .get(`${environment.domain}/${path}`, this.optionsRequest(header, {}, false, 'blob'))
      .pipe(catchError(this.handleError));
  }

  optionsRequest(
    headers: any = {},
    param: any = {},
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | '',
    body: any = null
  ) {
    const token = this.getToken();
    const header = {
      Authorization: token ? `Bearer ` + token : ``,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Accept-Language': this.language,
      ...headers
    };
    const options: any = { headers: new HttpHeaders(header), params: param };

    if (reportProgress == true) {
      options['reportProgress'] = true;
    }

    if (responseType != '' && responseType != null && responseType != undefined) {
      options['responseType'] = responseType;
    }

    if (body != null) {
      options['body'] = JSON.stringify(body);
    }

    return options;
  }

  /**
   * Handle Error
   * @param error
   * @returns
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error?.status}, ` + `body was: ${error?.error}`);
    }

    if (error.error.message) {
      return throwError(() => `API Request Error: ${error.error.message}`);
    }

    if (error.error.title && error.error.errors) {
      let message = Object.entries(error.error.errors).map(([key, value]) => `${key}: ${value}`) .join(', ');;
      return throwError(() => `API Request Error ${error.error.title}: ${message}`);
    }

    // return an observable with a user-facing error message
    return throwError(() => error);
    // 'Something bad happened; please try again later.');
  }
}
