import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { VexBreadcrumbsComponent } from "@vex/components/vex-breadcrumbs/vex-breadcrumbs.component";
import { VexPageLayoutContentDirective } from "@vex/components/vex-page-layout/vex-page-layout-content.directive";
import { VexPageLayoutHeaderDirective } from "@vex/components/vex-page-layout/vex-page-layout-header.directive";
import { VexPageLayoutComponent } from "@vex/components/vex-page-layout/vex-page-layout.component";
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppLoadingComponent } from 'src/app/core/components/app-loading/app-loading.component';
import { RouterLink } from '@angular/router';
import { AppFormErrorComponent } from 'src/app/core/components/app-form-error/app-form-error.component';

@NgModule({
    imports: [
        VexSecondaryToolbarComponent,
        VexPageLayoutComponent,
        VexPageLayoutHeaderDirective,
        VexBreadcrumbsComponent,
        MatButtonToggleModule,
        ReactiveFormsModule,
        VexPageLayoutContentDirective,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        NgFor,
        NgClass,
        MatPaginatorModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        VexBreadcrumbsComponent,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        NgFor,
        MatSelectModule,
        MatOptionModule,
        TranslateModule,
        AppLoadingComponent,
        AppFormErrorComponent,
        RouterLink,
        DatePipe
    ],
    exports: [
        VexSecondaryToolbarComponent,
        VexPageLayoutComponent,
        VexPageLayoutHeaderDirective,
        VexBreadcrumbsComponent,
        MatButtonToggleModule,
        ReactiveFormsModule,
        VexPageLayoutContentDirective,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        NgFor,
        NgClass,
        MatPaginatorModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        VexBreadcrumbsComponent,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        NgFor,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        TranslateModule,
        AppLoadingComponent,
        AppFormErrorComponent,
        RouterLink,
        DatePipe
    ],
})
export class SharedModule { }
